<template>
  <section>
    <header class="mb-2">
      <b-card>
        <b-row>
          <b-col md="2" sm="12">
            <p><strong>Tanggal</strong></p>
            <p>{{ humanDate(ck.tanggal) }}</p>
          </b-col>
          <b-col md="3" sm="12">
            <p><strong>Driver</strong></p>
            <p>{{ ck.driver?.nama_lengkap }}</p>
          </b-col>
          <b-col md="4" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ ck.keterangan }}</p>
          </b-col>
          <b-col md="3" sm="12">
            <p><strong>Kendaraan</strong></p>
            <p>
              {{ ck.kendaraan?.nama_kendaraan ? ck.kendaraan.nama_kendaraan : "kosong" }}
            </p>
          </b-col>
        </b-row>
      </b-card>
    </header>

    <main>
      <b-card title="Rincian Pengecekan">
        <!-- Modal ubah status -->
        <b-button variant="primary" @click.prevent="add">
          <feather-icon icon="PlusIcon" class="mr-50" />Rincian Pengecekan
        </b-button>
        <b-modal
          v-model="showModal"
          id="modal-ubah"
          cancel-variant="secondary"
          ok-:title="$t('Edit')"
          cancel-title="Batal"
          centered
          title="Form"
        >
          <b-overlay :show="loading">
            <!-- <strong>{{ checkedList.length }} Bagian telah dipilih.</strong> -->
            <b-form-group label="Pilih Bagian">
              <b-form-select :options="bagian_id" v-model="form.bagian_id" />
            </b-form-group>
            <b-form-group label="Pilih Kondisi">
              <b-form-select :options="optkondisi" v-model="form.kondisi" />
            </b-form-group>
            <!-- <b-form-group label="Pilih Kondisi">
          <b-form-select :options="optkondisi" v-model="selectedStatus" />
        </b-form-group> -->
          </b-overlay>
          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button class="mr-1" variant="info" @click="submit"> Ubah </b-button>
            </section>
          </template>
        </b-modal>
        <!-- / -->
        <b-row>
          <!-- <b-col v-if="checkedList.length > 0" sm="12" md="4" class="mb-2">
        <b-button @click.prevent="showModal = true" variant="primary" size="sm">
          Ubah Kondisi
        </b-button>
      </b-col> -->
          <b-col cols="12">
            <b-table
              striped
              small
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #head(checkbox)>
                <b-form-checkbox v-model="allChecked" />
              </template>
              <template #cell(checkbox)="{ item }">
                <b-form-checkbox v-model="checkedList" :value="item" />
              </template>

              <template #cell(no)="row">
                <strong class="text-center">
                  {{ row.index + 1 }}
                </strong>
              </template>

              <template #cell(actions)="row">
                <b-button
                  title="'Ubah'"
                  size="sm"
                  @click="edit(row.item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  title="'Hapus'"
                  size="sm"
                  @click="remove(row.item)"
                  class="mr-1"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>

              <!-- <template #cell(status)="{ item }">
            <div v-html="getStatus(item.status)"></div>
          </template> -->
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </main>
  </section>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BModal,
  BOverlay,
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    ToastificationContent,
    VBTooltip,
    BDropdown,
    BCard,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      selectedStatus: null,
      keterangan: null,
      checkedList: [],
      allChecked: false,
      showModalEdit: false,
      showModalAdd: false,
      showModalCek: false,
      id: null,
      ck: {},
      form: {
        // ceklist : "",
        kondisi: 0,
        // kendaraan_id: "",
        bagian_id: "",
      },
      kendaraan_id: [],
      bagian_id: [],

      perPage: 20,
      pageOptions: [20, 25, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // {
        //   key: "checkbox",
        //   label: "#",
        // },
        { key: "bagian.bagian", label: "Bagian", sortable: true },
        { key: "kondisi", label: "Kondisi" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      kendaraan: null,
      optkondisi: [
        { value: "Bagus", text: "Bagus" },
        { value: "Ganti", text: "Ganti" },
        { value: "Kurang", text: "Kurang" },
        { value: "Perbaikan", text: "Perbaikan" },
        { value: "Add", text: "Add" },
      ],
      status: [
        {
          1: "Bagus",
          2: "Ganti",
          3: "Kurang",
          4: "Perbaikan",
          5: "Add",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.checkedList = [];
        this.bagians.map((bagian) => {
          this.checkedList.push(bagian);
        });
      } else {
        this.checkedList = [];
      }
    },
  },
  computed: {
    thereChecked() {
      return this.bagians.filter((bagian) => bagian.checked == true).length > 0;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getBagian2();
    this.getrincian();
    this.gettanggal();
  },
  methods: {
    async gettanggal() {
      const ck = await this.$store.dispatch("ceklist/getDataById", this.$route.params.id);
      this.ck = ck;
    },
    async getrincian() {
      // get ceklist
      this.loading = true;
      const checklists = await this.$store.dispatch("ceklist/getDataRincian", {
        id_ceklist_kendaraan: this.$route.params.id,
        order: "asc",
      });
      this.loading = false;
      this.items = checklists;
      this.totalRows = checklists.length;
    },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data ceklist "${item.kondisi}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("ceklist/saveRincian", [item])
            .then(() => {
              this.displaySuccess({
                message: "Rincian pengecekan berhasil dihapus",
              });
              this.getrincian();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        // ceklist: "",
        kondisi: null,
        bagian_id: null,
      };
    },
    async submit() {
      if (!this.form.bagian_id) {
        this.displayError({
          message: "Harap lengkapi form dengan benar!",
        });
        return false;
      }

      let payload = this.form;
      if (this.id) [(payload.id = this.id)];

      this.loading = true;
      try {
        if (this.isAdminGudang) {
          payload.id_ceklist_kendaraan = this.$route.params.id;
          // payload.id_driver = this.gk.driver.id;
          // payload.id_gudang = this.gk.id;
        }
        await this.$store.dispatch("ceklist/saveRincian", [payload]);
        this.loading = false;
        this.displaySuccess({
          message: "Rincian pengecekan berhasil disimpan",
        });
        setTimeout(() => {
          this.showModal = false;
          this.resetForm();
          this.getrincian();
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
      }
      // if (!this.selectedStatus) {
      //   this.displayError({
      //     message: "Harap pilih kondisi bagian!",
      //   });

      //   return false;
      // }

      // this.loading = true;
      // try {
      //   // set payload
      //   let payload = [];
      //   this.checkedList.map((bagian) => {
      //     let item = {
      //       kendaraan_id: this.kendaraan.id, // gudang_id dengan jenis 2
      //       bagian_id: bagian.id,
      //       status: this.selectedStatus,
      //     };

      //     if (this.keterangan) {
      //       item.keterangan = this.keterangan;
      //     }

      //     if (bagian.checklist_id) {
      //       item.id = bagian.checklist_id;
      //     }
      //     payload.push(item);
      //   });
      //   await this.$store.dispatch("ceklist/save", payload);
      //   this.displaySuccess({
      //     message: "Data berhasil tersimpan",
      //   });
      //   this.loading = false;
      //   this.showModal = false;
      //   this.allChecked = false;
      //   this.checkedList = [];
      //   this.getKendaraan();
      // } catch (e) {
      //   this.loading = false;
      //   this.displayError(e);
      // }
    },
    edit(item) {
      let { id, bagian } = item;
      this.id = item.id;
      this.form = item;
      if (bagian) {
        this.form.bagian_id = bagian.id;
      }
      this.showModal = true;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getDatakendaraan() {
      this.$store.dispatch("kendaraan/getData", {}).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.kendaraan.datas));
        ter.map((item) => {
          item.value = item.id;
          item.text = item.nama_kendaraan + " - " + item.merk_kendaraan;
        });
        this.kendaraan_id = ter;
      });
    },
    getStatus(status) {
      switch (status) {
        case 1:
          status = `<strong class="text-success">BAGUS</strong>`;
          break;
        case 2:
          status = `<strong class="text-warning">GANTI</strong>`;
          break;
        case 3:
          status = `<strong class="text-info">KURANG</strong>`;
          break;
        case 4:
          status = `<strong class="text-danger">PERBAIKAN</strong>`;
          break;
        case 5:
          status = `<strong class="text-dark">TAMBAH</strong>`;
          break;
      }

      return status;
    },
    async getBagian2() {
      await this.$store
        .dispatch("bagian/getData")
        .then(() => {
          let bagians = JSON.parse(JSON.stringify(this.$store.state.bagian.datas));
          bagians.map((item) => {
            item.value = item.id;
            item.text = item.bagian;
          });
          this.bagian_id = bagians;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    // async getBagian() {
    //   this.bagians = [];
    //   const bagians = await this.$store.dispatch("bagian/getData");
    //   bagians.map((bagian) => {
    //     bagian.status = 1;
    //     bagian.checked = false;
    //     bagian.checklist_id = null;
    //     const alreadyChecklist = this.checklists.find(
    //       (check) => check.bagian && check.bagian.id == bagian.id
    //     );
    //     if (alreadyChecklist) {
    //       bagian.status = alreadyChecklist.status;
    //       bagian.checklist_id = alreadyChecklist.id;
    //     }

    //     this.bagians.push(bagian);
    //   });
    // },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
